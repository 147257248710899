import classes from "./about.module.css";

function About() {

    return (
        <>
            <div className={classes.aboutMain}>
                <h1>Welcome</h1>
                <p>Hi Iam Pal Szenthe, and this is my photographer portfolio!</p>
                <p>I shoot photos daily, and I quite enjoy the artistic qualities of the photo elaboration</p>
                <p>I like walking in nature and working around the countryside with animals and the landscape as well</p>
                <p>I shooted many pictures on the Azores, and around Vancouver</p>
                <p>Currently I am bethween jobs, and using photography mainly as a hobby</p>
            </div>
        </>
    );
};

export default About;